// import Vue from 'vue'
// import Vuex from 'vuex'

// Vue.use(Vuex)

const state = {
  name: 'Vidya Siva',
  designation:'Full stack Developer',
  intro:"I'm a 23 year old software develeoper with a 3D background, I started my career when I was 19 as a 3D artist, Later in few months I shifted to ",
  locN:'',
  des:'',
  activeMap:{
    isActive: false
  },
  version:'v.0.0.1'
  // center: latLng(39.903416, 32.858900),
  
}

const getters = {
  name: state => state.name,
  // center: state => latLng(state.Lat,state.Lon),
  designation: state => state.designation,
  intro: state => state.intro,
  windowData: state => state.activeMap.isActive
  


}

const mutations = {
  increment (state) {
    state.count++;
  },
  decrement (state) {
    state.count--;
  }
}



// export default new Vuex.Store({
//   state,
//   getters,
//   mutations,
// })

import { createStore } from "vuex";
export default createStore({
  state,
  getters,
  mutations,
})
// export default {
//   name: 'Store',

// }

