// import Vue from "vue";
import { createRouter, createWebHistory } from 'vue-router';

// Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('../components/Home.vue')
	},
	// {
	// 	path: '/about',
	// 	name: 'About',
	// 	component: () => import('../components/aboutMe.vue')
	// },
	// {
	// 	path: '/present',
	// 	name: 'Present',
	// 	component: () => import('../components/present.vue')
	// }
	{
		path: '/g2g',
		name: 'G2G',
		component: () => import('../components/G2G.vue')
	}
]

const router = createRouter({
	// mode: 'history',
	// base: process.env.BASE_URL,
	history: createWebHistory(),
	routes
})

export default router