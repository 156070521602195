import {createApp} from "vue";
// import { createStore } from "vuex";
import store from './store'
import App from "./App.vue";
import router from './router'

// Create a new store instance or import from module.
// const store = createStore({
//   /* state, actions, mutations */
// });
const app = createApp(App);
app.use(store);
app.use(router);
app.mount("#app");
// new Vue({
//     store,
//     router,
//     // vuetify,
//     render: h => h(App)
//   }).$mount('#app')