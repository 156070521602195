<template>
  <body>
    <router-view />
    <!-- <HomePage /> -->
    <!-- <aboutMe /> -->
  </body>
</template>

<script>
// import aboutMe from './components/aboutMe.vue'
// import HomePage from './components/Home.vue'

export default {
  name: 'App',
  components: {
    // HomePage,
    // aboutMe
  }
}
</script>

<style>
body{
  margin: 0;
  padding: 0;
  background-color: #1c1c1c;
  color: #fff;
  position: relative;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
